export default {
  methods: {

    taxAmount (grossPrice, businessTax, VAT = 12, transactionType) {
      if (['Vatable'].includes(businessTax) && ['With-2307'].includes(transactionType)) {
        return parseFloat(parseFloat(grossPrice) - (parseFloat(grossPrice) * (100 / (100 + parseFloat(VAT))))).toFixed(2)
      }

      return parseFloat(0.00).toFixed(2)
    },

    taxableAmount (grossPrice, taxAmount, businessTax, transactionType) { // netPrice
      if (['Vatable'].includes(businessTax) && ['With-2307'].includes(transactionType)) {
        return parseFloat(parseFloat(grossPrice) - parseFloat(taxAmount)).toFixed(2)
      }

      // return parseFloat(['With-2307'].includes(transactionType) ? grossPrice : 0.00).toFixed(2)
      return parseFloat(grossPrice).toFixed(2)
    },

    withHoldingTax (taxableAmount, percentage, taxStatus, transactionType) {
      if (['Liable'].includes(taxStatus) && ['With-2307'].includes(transactionType)) {
        return parseFloat(parseFloat(taxableAmount) * (parseFloat(percentage) / 100)).toFixed(2)
      }

      return parseFloat(0.00).toFixed(2)
    },

    amountDue (grossPrice, withHoldingTax) {
      return parseFloat(parseFloat(grossPrice) - parseFloat(withHoldingTax)).toFixed(2)
    },

    vatCalculator (grossPrice, businessTax, taxStatus, percentage, transactionType = 'Without-2307') {
      const taxAmount = this.taxAmount(grossPrice || 0.00, businessTax, 12, transactionType)
      const taxableAmount = this.taxableAmount(grossPrice || 0.00, taxAmount, businessTax, transactionType)
      const withHoldingTax = this.withHoldingTax(taxableAmount, percentage, taxStatus, transactionType)
      const amountDue = this.amountDue(grossPrice || 0.00, withHoldingTax, transactionType)

      return {
        taxAmount, taxableAmount, withHoldingTax, amountDue
      }
    }
  }
}
